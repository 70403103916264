.about-us {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0 30px;
  margin: auto;
}

.david {
  position: relative;
  width: 80%;
  max-width: 350px;
  margin: 50px auto;

  &__image {
    position: relative;
    max-width: 70%;
    width: 260px;
    min-width: 160px;
  }

  &__description {
    color: $black;
    line-height: 1.5;

    p {
      margin: 25px 0 15px;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }

  &__instagram {
    left: -5px;
  }
}

.mariam {
  position: relative;
  width: 80%;
  max-width: 350px;
  margin: 70px auto;
  text-align: center;

  &__image {
    position: relative;
    display: inline-block;
    max-width: 70%;
    width: 260px;
    min-width: 160px;
  }

  &__img {
    display: block;
    width: 100%;
  }

  &__description {
    color: $black;
    line-height: 1.5;

    p {
      margin: 25px 0 15px;
    }

    strong {
      font-style: italic;
      font-weight: 600;
    }
  }

  &__click {
    font-size: .7em;
  }
}

@media (min-width: $tablet + "px") {
  .david {
    display: grid;
    width: 690px;
    max-width: unset;
    grid-template-columns: 300px 80px 300px;

    &__image {
      width: 100%;
      max-width: unset;
      grid-column: 1 / span 1;
    }

    &__content {
      grid-column: 3 / span 1;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
    }
  }

  .mariam {
    display: grid;
    width: 690px;
    max-width: unset;
    grid-template-columns: 300px 80px 300px;

    &__image {
      width: 100%;
      max-width: unset;
      grid-column: 3 / span 1;
    }

    &__content {
      grid-column: 1 / span 1;
      grid-row: 1 / 2;
      margin-top: 50px;
    }
  }
}

@media (min-width: $laptop + "px") {
  .david {
    width: 800px;
    grid-template-columns: 1fr 100px 1fr;
  }

  .mariam {
    width: 800px;
    grid-template-columns: 1fr 100px 1fr;
  }
}

@media (min-width: $hd + "px") {
  .david {
    width: 1000px;
    grid-template-columns: 1fr 100px 1fr;

    &__content {
      margin-top: 50px;
    }
  }

  .mariam {
    width: 1000px;
    grid-template-columns: 1fr 100px 1fr;

    &__content {
      margin-top: 140px;
    }
  }
}