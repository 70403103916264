/*!
Theme Name: David Production
Author: Lado Mikiashvili
Description: Site of Videographer
Version: 0.0.1
Tags: minimalizm
*/

@import "main";
@import "header";
@import "portfolio";
@import "prices";
@import "about";
@import "contact";
@import "404";