.prices {
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
}

.plans {
  position: relative;
  width: 100%;
  margin: 20px auto 50px;
  overflow: auto;

  &__horizontal-split {
    display: none;
  }

  &__vertical-split {
    display: none;
  }
}

.plan {
  position: relative;
  display: block;
  width: 320px;
  margin: 121px auto 0;
  text-align: center;
  color: $secondary_black;

  &:before {
    content: '';
    position: absolute;
    left: 20%;
    top: -60px;
    display: block;
    width: 60%;
    height: 1px;
    background-image: linear-gradient(90deg, $black, $black 40%, transparent 40%, transparent 100%);
    background-size: 10px 1px;
  }

  &:first-of-type {
    margin-top: 0;

    &:before {
      display: none;
    }
  }

  &__price {
    font-size: 17px;
  }

  &__title {
    margin: 0;
    font-family: $secondary_font;
    font-size: 35px;
    letter-spacing: 2px;
    color: $secondary_black;
  }

  &__image {
    display: block;
    width: 185px;
    margin: 20px auto 0;
  }

  &__img {
    display: block;
    width: 100%;
  }

  &__list {
    display: block;
    padding: 0;
    margin: 30px auto 0;
    list-style: none;
  }

  &__service {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__book {
    position: relative;
    padding: 5px 10px;
    margin-top: 40px;
    font-family: $secondary_font;
    font-size: 23px;
    line-height: 25px;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      left: 30%;
      bottom: 0;
      width: 40%;
      height: 2px;
      background-color: $secondary_black;
      transition: .3s;
    }

    &:hover:after {
      left: 20%;
      width: 60%;
    }
  }
}



@media (min-width: $tablet + "px") {

  .plans {
    display: grid;
    grid-template-column: 1fr 100px 1fr;
    grid-gap: 50px 20px;
    width: 700px;
    margin-top: 70px;
    margin-bottom: 70px;


    &__horizontal-split {
      grid-column: 1 / span 3;
      display: block;
      width: 40%;
      height: 1px;
      background-image: linear-gradient(90deg, $black, $black 40%, transparent 40%, transparent 100%);
      background-size: 15px 1px;
      margin: auto;
    }

    &__vertical-split {
      display: block;
      width: 1px;
      height: 200px;
      background-image: linear-gradient(180deg, $black, $black 40%, transparent 40%, transparent 100%);
      background-size: 1px 15px;
      margin: auto;
    }
  }

  .plan {
    margin-top: 0;

    &:before {
      display: none;
    }
  }
}

@media (min-width: $laptop + "px") {

  .plans {
    grid-gap: 80px 20px;
    width: 900px;
  }
}

.body--lang-ka {

  .plan__title,
  .plan__book {
    font-family: "BPG WEB 002 Caps", sans-serif;
  }
}

.booking-modal {
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(#fff, .98);
  z-index: 600;
  transition: .3s;

  &--opening {
    display: block;
  }


  &__close-button {
    position: fixed;
    top: 20px;
    right: 30px;
    width: 30px;
    padding: 0 5px;
    cursor: pointer;
    z-index: 700;
  }

  &__close-img {
    display: block;
    width: 100%;

    line {
      stroke: $primary_black;
    }
  }

  &__wrap {
    position: relative;
    display: block;
    width: 90%;
    max-width: 400px;
    margin: auto;
  }

  &__title {
    font-size: 30px;
    margin-top: 80px;
    margin-bottom: 30px;
    text-align: left;
    font-weight: 900;
  }

  &__choose,
  &__incorrect {
    display: inline-block;
    font-size: 18px;
    margin-bottom: 10px;
    visibility: hidden;
    opacity: 0;
    transition: .1s;
  }

  @at-root .body .booking-modal__wrap .booking-modal--visible {
    visibility: visible;
    opacity: 1;
    transition: .3s;
  }

  @media (min-width: $laptop + 'px') {
    &__title {
      text-align: center;
    }

    &__wrap {
      max-width: 850px;
    }

    &__choose,
    &__incorrect {
      position: absolute;
      left: calc(50% + 25px);
      top: 170px;
    }
  }

  @media (min-width: $hd + 'px') {
    &__wrap {
      max-width: 1000px;
    }
  }
}

.wpbc_only_calendar {
  width: 100%;

  .bk_calendar_frame {
    width: 100%!important;
  }
}

.wpbc_booking_form_structure {

  .wpbc_structure_calendar {
    position: relative;
    display: block;
    width: 100%;

    .bk_calendar_frame {
      width: 100%!important;

      .date_approved {
        background-color: transparent;
        cursor: pointer;

        &:hover {
          background-color: #aaaaaa;
        }

        span {
          text-shadow: none;
        }
      }
    }
  }

  .wpbc_structure_form {
    position: relative;
    display: block;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: .1s;

    .form-group {
      text-align: left;

      &:first-of-type {
        display: none;
      }

      input {
        display: block;
        box-sizing: border-box;
        width: 100%!important;
        height: unset;
        padding: 10px 20px;
        border: 2px solid $light_gray;
        border-radius: 0;
        margin-top: 5px;
        font-size: 19px;
        font-weight: 400;
        font-family: $main_font;
        transition: .2s;
        outline: none;

        &::placeholder {
          color: $light_gray;
          transition: .2s;
        }

        &:focus {
          border-color: $primary_black;
          &::placeholder {
            color: $secondary_black;}
        }
      }

      .captachinput {
        max-width: 200px;
        margin: 0 !important;
      }

      textarea {
        display: block;
        box-sizing: border-box;
        width: 100%!important;
        height: unset;
        padding: 10px 20px;
        border: 2px solid $light_gray;
        border-radius: 0;
        margin-top: 5px;
        resize: vertical;
        font-size: 16px;
        font-weight: 400;
        font-family: $main_font;
        transition: .2s;
        outline: none;

        &::placeholder {
          color: $light_gray;
          transition: .2s;
        }

        &:focus {
          border-color: $primary_black;
          &::placeholder {
            color: $secondary_black;}
        }
      }

      .btn-default {
        display: inline-block;
        padding: 10px 15px;
        background-color: transparent;
        border: 2px solid $primary_black;
        margin-top: 15px;
        color: $primary_black;
        font-size: 20px;
        font-weight: 600;
        outline: none;
        transition: .2s;
        cursor: pointer;

        &:hover {
          background-color: $primary_black;
          color: #fff;
        }
      }

      .control-label {
        display: inline-block;

        &[for="date1"],
        &[for="plan1"],
        &[for="price1"] {
          width: 30%;
        }

        &[for="date1"] + .controls,
        &[for="plan1"] + .controls,
        &[for="price1"] + .controls {
          width: 65%!important;
          display: inline-block;
        }
      }

      #date1, #plan1, #price1 {
        font-size: 23px;
        padding: 0;
        background-color: transparent;
        border: none;
      }


    }
  }

  @media (min-width: $laptop + 'px') {
    .wpbc_structure_calendar {
      display: inline-block;
      width: 400px;
      overflow: hidden;
    }

    .wpbc_structure_form {
      display: inline-block;
      width: 400px;
      margin-left: 40px;
      vertical-align: top;
    }
  }

  @media (min-width: $laptop + 'px') {
    .wpbc_structure_form {
      margin-left: 115px;
    }
  }
}

.body {
  .datepick-inline .datepick, .datepick-inline .datepick-header, .datepick-inline .datepick-header span, .datepick-inline .calendar-links, .datepick-inline .calendar-links a, .datepick-inline .datepick-days-cell, .datepick-inline .datepick-days-cell a, .datepick-inline .datepick-days-cell div, .datepick-inline .datepick-title-row, .datepick-inline .datepick-title-row th {
    font-size: 17px;
  }
}





























